import Swiper, {Autoplay, Navigation} from '../../trienergy-b2b/node_modules/swiper';

export function manipulateSlideshowItemboxPreview(query = document.querySelectorAll('.swiper-initialized.itemboxList--5')) {
    Swiper.use([Autoplay, Navigation]);
    for (let slideshow of query) {
        const slideshowSwiper = slideshow.swiper;
        slideshowSwiper.destroy(true, true);

        new Swiper(slideshow, {
            slidesPerView: 1.2,
            spaceBetween: 20,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            autoHeight: false,
            autoplay: false,
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                540: {
                    slidesPerView: 2
                },
                860: {
                    slidesPerView: 3
                },
                1250: {
                    slidesPerView: 4
                }
            }
        });
    }
}